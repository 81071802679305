<template>
    <div class="card">
        <div class="card-header">Organisatiebeheer</div>

        <div class="card-body">
            <table class="table">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>E-mail</th>
                    <th>Telefoon</th>
                    <th>Locaties</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="org in organisations" :key="org.id">
                    <td>{{org.name}}</td>
                    <td>{{org.email}}</td>
                    <td>{{org.telephone}}</td>
                    <td>{{org.locations.length}}</td>
                    <td>
                        <a @click.prevent.stop="editOrganisation(org)" title="Wijzig deze organisatie">
                            <span class="inline-icon edit-icon"></span>
                        </a>
                        <a v-if="org.locations.length == 0" @click.prevent.stop="deleteOrganisation(org)" title="Verwijder deze organisatie">
                            <span class="inline-icon delete-icon"></span>
                        </a>
                        <a @click.prevent.stop="editLocations(org)" title="Beheer de locaties">
                            <span class="inline-icon location-icon"></span>
                        </a>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <input id="new_org_name" type="text" name="new_org_name" v-model="newOrg.name" placeholder="Naam nieuwe organisatie" :class="{error: neworg_errors.name}" />
                            <p class="error" v-if="neworg_errors.name">{{neworg_errors.name[0]}}</p>
                        </td>
                        <td>
                            <input id="new_org_email" type="text" name="new_org_email" v-model="newOrg.email" placeholder="E-mail adres" :class="{error: neworg_errors.email}" />
                            <p class="error" v-if="neworg_errors.email">{{neworg_errors.email[0]}}</p>
                        </td>
                        <td>
                            <input id="new_org_telephone" type="text" name="new_org_telephone" v-model="newOrg.telephone" placeholder="Telefoon" :class="{error: neworg_errors.telephone}" />
                            <p class="error" v-if="neworg_errors.telephone">{{neworg_errors.telephone[0]}}</p>
                        </td>
                        <td></td>
                        <td>
                            <button type="submit" class="btn btn-primary" id="add-user-btn" @click.stop="addOrganisation">Opslaan</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="card-footer">
        </div>

        <div class="modal fade" tabindex="-1" role="dialog" id="edit_org_model">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title">Organisatie wijzigen</h4>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" v-if="errors.length > 0">
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </div>

                        <div class="form-group">
                            <label for="org_name">Naam:</label>
                            <input type="text" name="org_name" id="org_name" class="form-control"  v-model="selectedOrg.name">
                            <p class="error" v-if="errors.name">{{errors.name[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="org_email">E-mail:</label>
                            <input type="text" name="org_email" id="org_email" class="form-control"  v-model="selectedOrg.email">
                            <p class="error" v-if="errors.email">{{errors.email[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="org_website">Website:</label>
                            <input type="text" name="org_website" id="org_website" class="form-control"  v-model="selectedOrg.website">
                            <p class="error" v-if="errors.website">{{errors.website[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="org_website">Telefoon:</label>
                            <input type="text" name="org_telephone" id="org_telephone" class="form-control"  v-model="selectedOrg.telephone">
                            <p class="error" v-if="errors.telephone">{{errors.telephone[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="org_type">Type:</label>
                            <select type="text" name="org_type" id="org_type" class="form-control"  v-model="selectedOrg.type">
                                <option value="main">Bibliotheek vestiging</option>
                                <option value="partner">Partner</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="org_type">Logo:</label><br/>
                            <img class="org-logo" :src="selectedOrg.logo" />
                            <span class="inline-icon delete-icon" title="Verwijder het logo" @click.stop="deleteLogo" v-if="selectedOrg.logo"></span>
                            <input type="file" name="org_logo" accept="image/*" @change="getImage($event)" id="org_logo">
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Annuleren</button>
                        <button type="button" @click="updateOrg()" class="btn btn-primary">Opslaan</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
    export default {
        data(){
            return {
                organisations: [],
                newOrg: {name: ''},
                errors: [],
                neworg_errors: [],
                selectedOrg: {}
            }
        },
        mounted() {
            axios.get('/api/organisations')
                .then(response => {
                    this.organisations = response.data;
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        methods: {
            deleteLogo(){
              this.selectedOrg.logo = '';
            },
            editLocations(org){
                location.href='orgLocations/' + org.id;
            },
            getImage(event){
                this.selectedOrg.logoFile = event.target.files[0];
                let reader = new FileReader();
                const vm = this;
                reader.onload = function(event) {
                    vm.selectedOrg.logo = event.target.result;
                };
                reader.readAsDataURL(this.selectedOrg.logoFile);
            },

            uploadImage(event) {
                alert(event.target.files[0]);
             },

             addOrganisation: function(){
                if (!this.newOrg.name){
                    alert('Vul eerst de naam in van de nieuwe organisatie');
                    return;
                }
                if (!this.newOrg.email){
                    alert('Vul een e-mail adres in van de nieuwe organisatie');
                    return;
                }
                if (!this.newOrg.telephone){
                    alert('Vul een telefoonnummer in van de nieuwe organisatie');
                    return;
                }
                axios.post('/api/organisations', this.newOrg)
                .then(response => {
                    if(response.data.result == 'success'){
                        this.organisations.push(response.data.org);
                        this.newOrg = {};
                    } else {
                        if(response.data.message)
                            alert(response.data.message);

                        if(response.data.errors)
                            this.neworg_errors = response.data.errors;
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            },

            deleteOrganisation: function (org){
                if(confirm('Deze organisatie permanent verwijderen?')) {
                    axios.delete('/api/organisations/' + org.id)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.organisations = this.organisations.filter(f => {
                                return f.id != org.id;
                            });

                            this.newOrg = {};
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
                }
            },

            updateOrg: function (){
                axios.patch('/api/organisations/' + this.selectedOrg.id, this.selectedOrg)
                    .then(response => {
                        if(response.data.result == 'success') {
                            this.selectedOrg = {};
                            // get the index of the updated org
                            let changedOrgIndex = this.organisations.findIndex(function(org){return(org.id == response.data.org.id)})
                            // replace the org using splice to force Vue to update
                            this.organisations.splice(changedOrgIndex, 1, response.data.org);
                            $("#edit_org_model").modal("hide");
                        } else {
                            if(response.data.message)
                                alert(response.data.message);

                            if(response.data.errors)
                                this.errors = response.data.errors;
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },

            editOrganisation: function (org){
                this.selectedOrg = { ...org };
                $("#edit_org_model").modal("show");
            },
        }
    }
</script>
<style>
    .org-logo{
        max-width: 300px;
        max-height: 100px;
        margin-bottom: 10px;
    }
    input.error{
        border: 1px solid red;
    }
</style>
