<template>
    <div class="flex-table">
        <div v-for="event in events"
             :key="event.id"
             :class="{
                 'selectable-row': !canedit,
                 'expanded-row': expandedEvent.id == event.id}"
             @click="eventRowClicked(event)">
             <div class="flex-row event-row">
                <div style="flex: 0 0 50px">
                    <span class="inline-icon"
                          :class="{'caret-right-icon': expandedEvent.id != event.id, 'caret-down-icon': expandedEvent.id == event.id}"
                          @click.stop="showEventInfo(event)">
                    </span>
                </div>
                <div style="flex: 1 1 50%">
                    {{event.title}}
                </div>
                <div style="flex: 1 1 50%" v-if="showlocation">{{event.location.name}}</div>
                 <div style="flex: 1 1 120px"><span v-if="event.bookunit">{{event.bookunit}} min.</span><span v-if="!event.bookunit">Inloop.</span></div>
                <div style="flex: 0 0 120px">
                    <a v-if="canedit" @click.prevent.stop="editEvent(event)" title="Wijzig dit spreekuur">
                        <span class="inline-icon edit-icon"></span>
                    </a>
                    <a v-if="canedit" @click.prevent.stop="deleteEvent(event)" title="Verwijder dit spreekuur">
                        <span class="inline-icon delete-icon"></span>
                    </a>
                    <a @click.prevent.stop="eventClicked(event)" title="Boekingscalender voor dit spreekuur">
                        <span class="inline-icon cal-icon" :class="{'icon-blocked': calendarBlocked(event)}"></span>
                    </a>
                </div>
            </div>
            <div class="flex-row info-row" v-show="expandedEvent.id == event.id">
                <div style="flex: 0 0 50px">
                </div>
                <div>
                    <p class="event-description">{{event.description}}</p>
                    <ul class="event-timelines">
                        <li v-for="timeline in timelines" v-html="timeline"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CollapsableEvent",
    data(){
        return {
            expandedEvent: {},
            timelines: [],
        }
    },
    props: ['events', 'canedit', 'showlocation'],
    methods: {
        calendarBlocked: function (event) {
            return (event.timepatterns.length == 0 || event.bookunit==0)
        },
        showEventInfo: function (event){
            if(this.expandedEvent == event){
                this.expandedEvent = {};
            } else {
                this.timelines = [];
                this.expandedEvent = event;
                axios.get('/api/event/' + event.id + '/info').then(response => {
                    this.timelines = response.data.patterns
                })
            }
        },
        eventRowClicked: function (event){
            if(!this.canedit && !this.calendarBlocked(event))
                location.href='/events/calendar/'+event.id;
        },
        editEvent: function(event){
            window.location = '/events/' + event.id + '/edit';
        },
        deleteEvent: function (event){
            if(confirm('Dit spreekuur permanent verwijderen?')) {
                axios.delete('/api/event/' + event.id)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.$emit('EventDeleted', event.id)
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            }
        },
        eventClicked: function (event){
            if(!this.calendarBlocked(event))
                location.href='/events/calendar/'+event.id;
        },
    }
}
</script>

<style scoped>

</style>
