// src/components/Calendar.vue

<template>
    <div class="bookings-calendar">
        <div class="righttop-icon question-icon" v-if="appointments.length" @click.stop="showBookings" title="Toon de namen bij de boekingen"></div>
        <div class="righttop-icon unlocked-icon" v-if="patternId && isLockable && !isLocked" @click.stop="toggleLock" title="Blokkeer deze dag"></div>
        <div class="righttop-icon locked-icon" v-if="patternId && isLocked" @click.stop="toggleLock" title="Deblokkeer deze dag"></div>
        <h3 class="bookings-title">{{curDate && curDate.format('ddd D MMM YYYY')}}</h3>
        <div v-if="patternId" class="time-blocks" :class="{locked: isLocked, unlocked: !isLocked}" @click.stop="createAppointmentDialog">
            <div class="time-block" v-for="block in timeBlocks" :data-time="block.start.format('YYYY-MM-DD HH:mm')">
                <div class="block-time">{{block.start.format('HH:mm')}}</div>
            </div>
            <div class="reservation"
                 :class="{appointment: !appointment.is_block, block: appointment.is_block}"
                 v-for="appointment in appointments"
                 :style="{top: appointment.top + '%', height: appointment.height + '%'}"
                 @click.stop="editAppointmentDialog(appointment)"
            >
                <div>{{appointment.name}}</div>
            </div>
        </div>
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">{{dialogTitle}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="showModal = false">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" @keyup="contentChanged=true">
                                     <fieldset class="form-group">
                                         <legend>Datum afspraak</legend>
                                        <div class="form-group">
                                            <date-picker v-model="dialogEvent.date" :config="options" @dp-change="contentChanged=true"></date-picker>
                                            <p class="error" v-if="errors.date">{{errors.date[0]}}</p>
                                        </div>
                                        <div class="form-group ">
                                            <label for="start-time">Van:</label>
                                            <input name="start-time" id="start-time2"
                                                   placeholder="hh:mm"
                                                   v-model="dialogEvent.startTime"
                                                   size="7"
                                            />
                                            <label for="end-time">&nbsp;Tot:</label>
                                            <input name="end-time" id="end-time2"
                                                   placeholder="hh:mm"
                                                   v-model="dialogEvent.endTime"
                                                   size="7"
                                            />
                                            <input
                                                type="checkbox"
                                                id="is_block_checkbox"
                                                @click.stop="blockBtnClick"
                                                v-model="dialogEvent.is_block">
                                            &nbsp;Blokkeer Tijdvak

                                            <p class="error" v-if="errors.startTime">{{errors.startTime[0]}}</p>
                                            <p class="error" v-if="errors.endTime">{{errors.endTime[0]}}</p>
                                        </div>
                                         <ul class="error-list" v-if="errors.date">
                                             <li v-for="error in errors.date">
                                                 {{error}}
                                             </li>
                                         </ul>
                                     </fieldset>
                                     <fieldset class="form-group" v-if="!dialogEvent.is_block">
                                        <legend>Cliëntgegevens</legend>
                                        <input class="form-control client-inputfield" placeholder="Naam cliënt" v-model="dialogEvent.naam" />
                                        <p class="error" v-if="errors.naam">{{errors.naam[0]}}</p>
                                        <input class="form-control client-inputfield" placeholder="E-mail adres" v-model="dialogEvent.email" />
                                        <p class="error" v-if="errors.email">{{errors.email[0]}}</p>
                                        <input class="form-control client-inputfield" placeholder="Adres" v-model="dialogEvent.adres" />
                                        <p class="error" v-if="errors.adres">{{errors.adres[0]}}</p>
                                        <input class="form-control client-inputfield" placeholder="telefoon" v-model="dialogEvent.telefoon" />
                                        <p class="error" v-if="errors.telefoon">{{errors.telefoon[0]}}</p>
                                        <input class="form-control client-inputfield" placeholder="Postcode" v-model="dialogEvent.postcode" />
                                        <p class="error" v-if="errors.postcode">{{errors.postcode[0]}}</p>
                                        <input class="form-control client-inputfield" placeholder="Plaats" v-model="dialogEvent.plaats" />
                                        <p class="error" v-if="errors.plaats">{{errors.plaats[0]}}</p>
                                         <textarea class="form-control" placeholder="opmerking" v-model="dialogEvent.opmerking" />
                                         <p class="error" v-if="errors.opmerking">{{errors.opmerking[0]}}</p>
                                     </fieldset>
                                    <p v-if="dialogEvent">Geboekt door: {{dialogEvent.userName}}</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="sendMail" v-if="showMailButton">E-mail</button>
                                    <button type="button" class="btn btn-secondary" @click="createLetter" v-if="showBriefButton">Brief</button>
                                    <button v-if="dialogEvent.id" type="button" class="btn btn-danger" @click="deleteAppointment(dialogEvent.id)">{{deleteBtnText}}</button>
                                    <button type="button" class="btn btn-primary" @click="saveAppointment">Opslaan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import moment from 'moment';

import Event from "./v_calendar_scheduler/model/Event";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import Month from "./v_calendar_scheduler/components/views/Month";
import Week from "./v_calendar_scheduler/components/views/Week";
import Day from "./v_calendar_scheduler/components/views/Day";

export default {
    data() {
        return {
            appointments:[],
            curDate: new moment(),
            bookUnit: 0,
            patternId: null,
            patternStatus: 'available',
            bookBlock: {start: null, end: null, startX: 0, endX: 0, X: 0},
            timeBlocks: [],
            dialogTitle: '',
            showModal: false,
            dialogEvent:{},
            options: {
                format: 'DD-MM-YYYY',
                useCurrent: false,
                locale: 'nl',
            },
            curEventId: null,
            errors:[],
            contentChanged: false,
            isLocked: false,
            canEdit: false,
            dayBookings: 0
        }
    },

    components: { datePicker },

    props: ['consultation', 'bookunit'],

    mounted() {
    },

    watch:{
        patternStatus: function (newStatus, oldStatus){
            if(newStatus != oldStatus)
                EventBus.$emit('change-status', this.patternId, newStatus, this.curDate);
        }
    },
    created (){
        EventBus.$on('event-clicked', (pattern) => {
            //console.log(pattern);
            if(pattern.bookunit==0){
                alert('Dit is een inloopspreekuur');
            } else {
                this.curEventId = pattern.id;
                this.curDate = pattern.date; // moment object
                this.bookUnit = pattern.bookunit;
                this.patternId = pattern.patId;
                this.setBookBlock(pattern.startTime.hour(), pattern.startTime.minute(), pattern.endTime.hour(), pattern.endTime.minute());
                this.calcBlocks();
                this.readAppointments();
            }

            // Echo.channel('spreekuren')
            //     .listen('BookingCreatedEvent', (e) => {
            //         this.readAppointments();
            // });
            // Echo.channel('spreekuren')
            //     .listen('BookingDeletedEvent', (e) => {
            //         this.readAppointments();
            //     });
            // Echo.channel('spreekuren')
            //     .listen('BookingUpdatedEvent', (e) => {
            //         this.readAppointments();
            // });
        });

        EventBus.$on('events-read', ()=>{
            // a complete week or month has been read in the calendar view
            this.reset();
            this.curDate = null;
            this.patternId = null;
        });

        EventBus.$on('show-booking', (booking)=>{
            // a booking has been clicked in the search window
            axios.get('/api/timepattern/' + booking.timepattern_id)
                .then(response => {
                    if(response.data.result=='success') {
                        this.curDate = new moment(booking.start); // moment object
                        this.bookUnit = response.data.timepattern.bookunit;
                        this.patternId = booking.timepattern_id;
                        let eventPattern = response.data.timepattern;
                        this.setBookBlock(eventPattern.startTime.substring(0, 2), eventPattern.startTime.substring(3, 2), eventPattern.endTime.substring(0, 2), eventPattern.endTime.substring(3, 2));
                        this.calcBlocks();
                        this.readAppointments(true);
                    } else {
                        if(response.data.message)
                            alert(response.data.message);

                        if(response.data.errors)
                            this.errors = response.data.errors;
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        });
    },
    computed: {
        isLockable: function(){
            return (this.dayBookings == 0 && !this.isLocked)
        },
        showMailButton: function (){
            return (this.dialogEvent.id && !this.contentChanged && !this.dialogEvent.is_block);
        },
        showBriefButton: function (){
            return (this.dialogEvent.id && !this.contentChanged && !this.dialogEvent.is_block);
        },
        deleteBtnText: function (){
            return (this.dialogEvent.is_block) ? 'Blokkering verwijderen' : 'Afspraak verwijderen';
        },
    },
    methods: {
        toggleLock(){
            axios.post('/api/event/' + this.curEventId + '/togglelock?date=' + this.curDate.format('Y-MM-DD'))
                .then(response => {
                    if(response.data.result=='success') {
                        this.isLocked = response.data.isLocked;
                        if(this.isLocked){
                            this.patternStatus = 'block';
                        } else {
                            this.patternStatus = 'available';
                        }
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        setBookBlock(start_h, start_m, end_h, end_m){
            this.bookBlock.start = this.curDate.clone();
            this.bookBlock.start.hour(start_h);
            this.bookBlock.start.minute(start_m);
            this.bookBlock.end = this.curDate.clone();
            this.bookBlock.end.hour(end_h);
            this.bookBlock.end.minute(end_m);
            this.bookBlock.startX = this.bookBlock.start.format('X');
            this.bookBlock.endX = this.bookBlock.end.format('X');
            this.bookBlock.X = this.bookBlock.endX - this.bookBlock.startX;
        },
        reset(){
            this.appointments = [];
            this.contentChanged = false;
        },
        showBookings(){
            // show the real names for a short period
            this.appointments.forEach(app=>{
                app.name = app.naam;
            });
            var self = this;
            setTimeout(function(){ self.hideNames() }, 4000);
        },
        hideNames(){
            this.appointments.forEach(app=>{
                app.name = 'Boeking';
            });
        },
        editAppointmentDialog(appointment) {
            this.dialogTitle = 'Afspraak wijzigen'
            this.errors = [];
            axios.get('/api/appointment/' + appointment.id)
                .then(response => {
                    if(response.data.result=='success') {
                        this.dialogEvent = response.data.appointment;
                        this.showModal = true;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);

                        if(response.data.errors)
                            this.errors = response.data.errors;
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        createAppointmentDialog(clickEvent) {
            if (this.isLocked) {
                alert('Deze dag is geblokkeerd');
            } else {
                let appointmentStart = moment(clickEvent.target.closest('.time-block').getAttribute('data-time'));
                if (this.consultation) {
                    this.resetDialog();
                    let appointmentEnd = appointmentStart.clone();
                    appointmentEnd.add(this.bookunit, 'minutes');
                    this.dialogEvent.date = appointmentStart.format('DD-MM-Y');
                    this.dialogEvent.startTime = appointmentStart.format('HH:mm');
                    this.dialogEvent.endTime = appointmentEnd.format('HH:mm');
                    this.dialogTitle = 'Afspraak Aanmaken';
                    this.showModal = true;
                }
            }
        },
        blockBtnClick(){
          if(!this.dialogEvent.is_block){
              this.dialogEvent.adres = '';
              this.dialogEvent.postcode = '';
              this.dialogEvent.email = '';
              this.dialogEvent.plaats = '';
              this.dialogEvent.telefoon = '';
          }
        },
        resetDialog(){
            this.dialogEvent.date = '';
            this.dialogEvent.naam = '';
            this.dialogEvent.adres = '';
            this.dialogEvent.postcode = '';
            this.dialogEvent.email = '';
            this.dialogEvent.plaats = '';
            this.dialogEvent.telefoon = '';
            this.dialogEvent.opmerking = '';
            this.dialogEvent.startTime = '';
            this.dialogEvent.endTime = '';
            this.dialogEvent.is_block = false;
            this.dialogEvent.user = {name: ''};
            this.dialogEvent.id = null;
            this.errors = [];
            this.contentChanged = false;
        },
        saveAppointment(){
            if(this.dialogEvent){
                let data = {...this.dialogEvent};
                // change date format from nl to us
                data.date = moment(this.dialogEvent.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                data.patternId = this.patternId;
                let url='';
                if(this.dialogEvent.id){
                    axios.patch('/api/event/' + this.consultation + '/appointment/' + this.dialogEvent.id, data)
                        .then(response => {
                            if(response.data.result === 'success') {
                                this.appointments = this.appointments.filter(app=>{return app.id != response.data.appointment.id});
                                let app = this.calcAppointmentPosition(response.data.appointment);
                                this.appointments.push(app);
                                this.resetDialog();
                                this.showModal = false;
                                this.patternStatus = response.data.status;
                            } else {
                                if(response.data.message)
                                    alert(response.data.message);

                                if(response.data.errors)
                                    this.errors = response.data.errors;
                            }
                        })
                        .catch(error => {
                            if(error.response.status == 401){
                                location.href='';
                            } else {
                                alert(error);
                            }
                        });
                } else {
                    axios.post('/api/event/' + this.consultation + '/appointment', data)
                        .then(response => {
                            if(response.data.result === 'success') {
                                let app = this.calcAppointmentPosition(response.data.appointment);
                                this.appointments.push(app);
                                this.resetDialog();
                                this.showModal = false;
                                this.patternStatus = response.data.status;
                            } else {
                                if(response.data.message)
                                    alert(response.data.message);

                                if(response.data.errors)
                                    this.errors = response.data.errors;
                            }
                        })
                        .catch(error => {
                            if(error.response.status == 401){
                                location.href='';
                            } else {
                                alert(error);
                            }
                        });
                }
            }
        },
        calcBlocks(){
            // Create the time-blocks for the selected pattern
            this.timeBlocks = [];
            let blockTime = this.bookBlock.start.clone();
            while(blockTime < this.bookBlock.end){
                let block = {start: blockTime.clone(), startX: blockTime.format('X')};
                blockTime.add(this.bookUnit, 'minutes');
                if(blockTime > this.bookBlock.end)
                    blockTime = this.bookBlock.end;
                block.end = blockTime.clone();
                block.endX = blockTime.format('X');
                this.timeBlocks.push(block);
            }
        },

        createLetter(){
            location.href = '/pdf/' + this.dialogEvent.id;
        },

        sendMail(){
            axios.post('/api/appointment/' + this.dialogEvent.id + '/sendmail')
                .then(response => {
                    if(response.data.result=='success'){
                        alert('E-mail is verzonden naar ' + this.dialogEvent.email);
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },

        readAppointments(showNames=false) {
            axios.get('/api/appointments/' + this.patternId + '?date=' + this.curDate.format('YYYY-MM-DD'))
            .then(response => {
                if(response.data.result=='success') {
                    this.reset();
                    response.data.events.forEach(app => {
                        app = this.calcAppointmentPosition(app);
                        this.appointments.push(app);
                    });
                    this.isLocked = response.data.isLocked;
                    this.canEdit = response.data.canEdit;
                    this.patternStatus = response.data.status;
                    this.dayBookings = response.data.dayBookings;
                    if(showNames)
                        this.showBookings();
                } else {
                    if(response.data.message){
                        alert(response.data.message);
                    }
                }
            })
            .catch(error => {
                if(error.response.status == 401){
                    location.href='';
                } else {
                    alert(error);
                }
            });
        },

        calcAppointmentPosition(app){
            // format(X) returns the unix timestamp (seconds) for given moment
            app.X = moment(app.start).format('X') - this.bookBlock.startX; // booking height in seconds
            app.height = (moment(app.end).format('X') - moment(app.start).format('X')) * 98 / this.bookBlock.X; // height of booking block as percentage of total booking block height
            app.top = app.X * 100.2 / this.bookBlock.X; // top of booking in percentage of booking block height
            app.name = (app.is_block) ? 'Blokkering' : 'Boeking';
            return app;
        },

        deleteAppointment(appId){
            let thingToDelete = (this.dialogEvent.is_block) ? 'blokkering' : 'afspraak';
            if(confirm('Deze ' + thingToDelete + ' definitief verwijderen?')) {
                axios.delete('/api/appointment/' + appId)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.appointments = this.appointments.filter(app => {
                                return app.id != appId
                            });
                            this.patternStatus = response.data.status;
                            this.resetDialog();
                            this.showModal = false;
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            }
        }
    }
}
</script>

<style>
.appointments-card{
    height: 100%;
}
.modal-body{
    padding: 5px 10px;
}
fieldset{
    margin: 0;
}
legend{
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}
.modal-dialog{
    overflow: scroll;
}
.bookings-calendar{
    height: 100%;
    width: 100%;
 }
.time-blocks{
    border: 1px solid black;
    height: 98%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.time-block{
    width: 100%;
    height: 100%;
    border: 1px solid white;
    font-family: "Times New Roman";
    font-size: 12px;
    padding-left: 4px;
    clear: both;
}
.time-blocks.locked .time-block{
    background-color: #fca388;
}
.time-blocks.unlocked .time-block{
    background-color: #F4F4F4;
}
.reservation{
    position: absolute;
    top: 100px;
    right: 5px;
    height: 100px;
    border-radius: 10px;
    z-index: 1;
    width: 75%;
    color: white;
    display: flex;
}
.appointment{
    background-color: #2176bd;
}
.block{
    background-color: #f74c2a;
}
.reservation>div{
    margin: auto;  /* Magic! */
}
.reservation:hover{
    cursor: pointer;
}
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.bookings-title{
    font-size: 15px;
    width: 100%;
    text-align: center;
}
.error-list>li{
    color: red;
}
.client-inputfield{
    margin-bottom: 1px;
}
</style>
