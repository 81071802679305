<template>
    <div>
        <div class="card">
            <div class="card-header" v-if="event">Wijzigen spreekuur</div>

            <div class="card-body">
                <div>
                    <label>Eigenaar spreekuur:</label>
                    <select name="owner_selector" v-model="event.owner_org_id" @change="getEventLocations">
                        <option
                            v-for="organisation in organisations"
                            :value="organisation.id"
                        >
                            {{organisation.name}}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="title">Naam:</label>
                    <input type="text" name="title" class="form-control" placeholder="Geef naam" id="title" v-model="event.title">
                </div>
                <div class="form-group">
                    <label for="description">Omschrijving:</label>
                    <input type="text" name="description" class="form-control" placeholder="Geef omschrijving" id="description" v-model="event.description">
                </div>
                <div class="form-group">
                    <label for="description">Extra info in e-mail:</label>
                    <input type="text" name="info" class="form-control" placeholder="Geef info" id="info" v-model="event.info">
                </div>
                <div class="form-group">
                    <label for="location_id">Locatie:</label>
                    <select name="location_id" id="location_id" v-model="event.location_id">
                        <option value=""></option>
                        <option v-for="location in locations" :value="location.id">{{location.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="bookunit">Blokgrootte:</label>
                    <select name="bookunit" id="bookunit" v-model="event.bookunit">
                        <option v-for="unit in [0,5,10,15,20,25,30,35,40,45,50,55,60]" :value="unit">{{unit}}</option>
                    </select>&nbsp;min.
                </div>

                <button type="submit" class="btn btn-primary" @click.stop="saveEvent">Opslaan</button>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">Datums en tijden voor {{event.title}}</div>
            <timepattern-component :eventid="eventid"></timepattern-component>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">Dagblokkeringen voor {{event.title}}</div>
            <dayblocks-component :eventid="eventid"></dayblocks-component>
        </div>

    </div>
</template>

<script>
export default {
    name: "EditEvent",
    props: ['eventid'],
    data(){
        return {
            event: {owner:{}, location:{}, info: ''},
            locations: [],
            organisations: []
        }
    },
    mounted(){
        axios.get('/api/event/' + this.eventid)
            .then(response => {
                if(response.data.result=='success') {
                   this.event = response.data.event;
                   this.organisations = response.data.organisations;
                   this.getEventLocations();
                } else {
                    if(response.data.message)
                        alert(response.data.message);
                }
            })
            .catch(error => {
                if(error.response.status == 401){
                    location.href='';
                } else {
                    alert(error);
                }
            });
    },
    methods: {
        getEventLocations: function (){
            // get the locations for the changed owner
            axios.get('/api/organisation/' + this.event.owner_org_id + '/eventlocations')
                .then(response => {
                    if(response.data.result=='success') {
                        this.locations = response.data.locations;
                        // check if event location is in the available locations
                        let currentLoc = this.locations.filter(loc=>{return loc.id == this.event.location_id});
                        if(currentLoc.length == 0) {
                            // curent location is not allowed anymore
                            if (this.locations.length == 1) {
                                // take the one and only possible location
                                this.event.location_id = this.locations[0].id;
                            } else {
                                this.event.location_id = null;
                            }
                        }
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        saveEvent: function(){
            axios.patch('/api/event/' + this.eventid, this.event)
                .then(response => {
                    if(response.data.result=='success') {
                        alert('Wijzigingen zijn opgeslagen');
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>
