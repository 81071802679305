/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and  , other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Popper from 'popper.js'

import Vue from "vue";
require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('timepattern-component', require('./components/TimePatterns.vue').default);
Vue.component('calendar-component', require('./components/Calendar.vue').default);
Vue.component('bookings-component', require('./components/Bookings.vue').default);
Vue.component('user-admin', require('./components/UserAdmin.vue').default);
Vue.component('org-admin', require('./components/OrganisationAdmin.vue').default);
Vue.component('loc-admin', require('./components/LocationsAdmin.vue').default);
Vue.component('events-admin', require('./components/EventsAdmin.vue').default);
Vue.component('events-list', require('./components/OrgEvents.vue').default);
Vue.component('dayblocks-component', require('./components/DayBlocks.vue').default);
Vue.component('edit-event-component', require('./components/EditEvent.vue').default);

import './components/v_calendar_scheduler/lib/main.css';
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//import EventBus from 'components/v_calendar_scheduler/components/EventBus.js';
window.EventBus = new Vue;

const app = new Vue({
    el: '#app'
});

