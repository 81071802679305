<template>
    <div class="card">
        <div class="card-header">Spreekuren van organisatie: {{orgName}}</div>

        <div class="card-body">
            <collapsable-events :events="ownEvents" :canedit="canEdit" :showlocation="true" @EventDeleted="handleEventDeleted"></collapsable-events>
        </div>
        <div class="card-footer">
            <button v-if="canEdit" @click.stop="newEvent">Maak nieuw spreekuur aan</button>
        </div>

        <div class="card-header" v-if="guestEvents.length">Spreekuren met gast toegang</div>

        <div class="card-body" v-if="guestEvents.length">
            <collapsable-events :events="guestEvents" :canedit="false" :showlocation="true"></collapsable-events>
        </div>

        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">{{dialogTitle}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="showModal = false">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" @keyup="contentChanged=true">
                                    <div class="form-group ">
                                        <label for="title-input">Naam:</label>
                                        <input class="form-control" id="title-input" name="title" v-model="selectedEvent.title"/>
                                        <p class="error" v-if="errors.title">{{errors.title[0]}}</p>
                                    </div>
                                    <div class="form-group ">
                                        <label for="description-input">Omschrijving:</label>
                                        <textarea class="form-control" id="description-input" name="title" v-model="selectedEvent.description"/>
                                        <p class="error" v-if="errors.description">{{errors.description[0]}}</p>
                                    </div>
                                    <div class="form-group ">
                                        <label for="description-input">Locatie:</label>
                                        <select class="form-control" id="location-input" name="location_id" v-model="selectedEvent.location_id">
                                            <option value=""/>
                                            <option v-for="location in locations" :key="location.id" :value="location.id">{{location.name}}</option>
                                        </select>
                                        <p class="error" v-if="errors.location_id">{{errors.location_id[0]}}</p>
                                    </div>
                                    <div class="form-group ">
                                        <label for="bookunit-input">Standaard blokgrootte:</label>
                                        <select class="form-control" id="bookunit-input" name="title" v-model="selectedEvent.bookunit">
                                            <option v-for="val in [0,5,10,15,20,25,30,35,40,45,50,55, 60]" :selected="val==selectedEvent.bookunit" :value="val">{{val}}</option>
                                        </select>
                                        <p class="error" v-if="errors.bookunit">{{errors.bookunit[0]}}</p>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" @click="saveEvent">Opslaan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

    </div>
</template>

<script>
    import CollapsableEvents from "./CollapsableEvents";
    export default {
        components: {CollapsableEvents},
        data(){
            return {
                ownEvents: [],
                guestEvents: [],
                locations: [],
                selectedEvent: {},
                dialogTitle: '',
                showModal: false,
                errors: [],
            }
        },

        created(){
        },

        props: ['orgId', 'orgName', 'userRole'],

        mounted() {
            this.resetDialog();
            axios.get('/api/events/organisation/' + this.orgId)
                .then(response => {
                    if(response.data.result == 'success') {
                        this.ownEvents = response.data.ownEvents;
                        this.guestEvents = response.data.guestEvents;
                        this.locations = response.data.locations;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        computed: {
            canEdit: function(){
                return (this.userRole != 'user');
            }
        },
        methods: {
            resetDialog: function (){
                this.selectedEvent = {bookunit: 30};
                this.selectedEvent.owner_org_id = this.orgId;
                this.showModal = false;
            },
            newEvent: function(event){
                this.dialogTitle = 'Nieuw spreekuur';
                this.showModal=true;
            },
            saveEvent: function (){
                this.errors=[];
                if(this.selectedEvent.id){
                    this.updateEvent();
                } else {
                    this.createEvent();
                }
            },
            createEvent: function (){
                axios.post('/api/events', this.selectedEvent)
                    .then(response => {
                        if(response.data.result == 'success') {
                            if(this.orgId == response.data.event.owner_org_id) {
                                this.ownEvents.push(response.data.event);
                            } else {
                                this.guestEvents.push(response.data.event);
                            }
                            this.resetDialog();
                        } else {
                            if(response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },
            updateEvent: function (){
                axios.patch('/api/event/' + this.selectedEvent.id, this.selectedEvent)
                    .then(response => {
                        if(response.data.result == 'success') {
                            let changedEventIndex = this.ownEvents.findIndex(function(ev){return(ev.id == response.data.event.id)})
                            // replace the org using splice to force Vue to update
                            if(changedEventIndex>=0)
                                this.ownEvents.splice(changedEventIndex, 1, response.data.event);
                            this.resetDialog();
                        } else {
                            if(response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },
            handleEventDeleted: function (eventId){
                this.ownEvents = this.ownEvents.filter(ev=>{return ev.id != eventId});
            }
        },
    }
</script>
<style>
    .event-row{
        padding: 4px 0 2px 0;
    }
    .selectable-row:hover{
        background-color: orange;
     }
    .flex-table{
        width: 100%;
    }
    .flex-row{
        width: 100%;
        display: flex;
    }
    .info-row{
        background-color: #f7efe3;
        font-style: italic;
    }
    .event-description{
        padding: 5px;
        margin: 0;
    }
    .event-timelines{
        margin: 5px;
        padding: 0px 0px 0px 15px;
    }
    .expanded-row{
        border: 1px solid  #f7efe3;
        background-color: lightgray;
    }
</style>
