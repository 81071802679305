import { render, staticRenderFns } from "./DayBlocks.vue?vue&type=template&id=bb1f767a&scoped=true&"
import script from "./DayBlocks.vue?vue&type=script&lang=js&"
export * from "./DayBlocks.vue?vue&type=script&lang=js&"
import style0 from "./DayBlocks.vue?vue&type=style&index=0&id=bb1f767a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb1f767a",
  null
  
)

export default component.exports