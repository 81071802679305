<template>
    <div class="card">
        <div class="card-header">Spreekuren beheer</div>

        <div class="card-body">
            <table class="table">
                <thead>
                <tr>
                    <th>Spreekuur</th>
                    <th>Eigenaar</th>
                    <th>Locatie</th>
                    <th>Tijd</th>
                    <th>#</th>
                    <th>Boekers<sup>*</sup></th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="event in events" :key="event.id">
                    <td>{{event.title}}</td>
                    <td>{{event.owner.name}}</td>
                    <td>{{event.location.name}}</td>
                    <td>{{event.bookunit}}</td>
                    <td>{{event.bookings.length}}</td>
                    <td class="nowrap">
                        <ul class="no-bullets">
                            <li v-for="org in event.bookorgs">
                                {{org.name}}
                                <a @click.prevent.stop="removeOrganisation(event, org)">
                                    <span class="inline-icon delete-icon"></span>
                                </a>
                            </li>
                            <li>
                                <a @click.prevent.stop="initAddOrganisation(event)" style="color: #EE7A3A">
                                    Organisatie koppelen&nbsp;<span class="inline-icon add-user-org add-icon"></span>
                                </a>
                            </li>
                        </ul>
                    </td>
                    <td class="nowrap">
                        <a @click.prevent.stop="editEvent(event)" title="Wijzig dit spreekuur">
                            <span class="inline-icon edit-icon"></span>
                        </a>
                        <a @click.prevent.stop="deleteEvent(event)" title="Verwijder dit spreekuur">
                            <span class="inline-icon delete-icon"></span>
                        </a>
                        <a @click.prevent.stop="eventClicked(event)" title="Ga naar de boekingscalender voor dit spreekuur">
                            <span class="inline-icon cal-icon" :class="{'icon-blocked': calendarBlocked(event)}"></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
            <p><sup>*</sup>Medewerkers van deze organisaties kunnen het spreekuur ook zien en boeken.</p>
        </div>

        <div class="modal fade" tabindex="-1" role="dialog" id="add_event_org">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h4 class="modal-title">Organisatie toevoegen voor {{selectedEvent.title}}</h4>
                        <p>Medewerkers van deze organisaties kunnen het spreekuur ook zien en boeken.</p>
                        <div class="alert alert-danger" v-if="errors.length > 0">
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </div>
                        <ul class="no-bullets">
                            <li v-for="org in organisations" :key="org.id">
                                <input type="checkbox" name="add_org" :value="org.id" class="add_org_checkbox" v-model="selectedOrgs" />
                                &nbsp;{{org.name}}
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" value="" @click="closeDialog">Annuleren</button>
                        <button type="button" @click="addOrgs" class="btn btn-primary">Opslaan</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
    export default {
        data(){
            return {
                events: [],
                organisations: [],
                selectedUser: {"name": ""},
                selectedEvent: {},
                selectedOrgs: [],
                errors: {},
            }
        },
        props: [],
        mounted() {
            axios.get('/api/events')
                .then(response => {
                    if(response.data.result == 'success') {
                        this.events = response.data.events;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        methods: {
            calendarBlocked: function (event) {
                return (event.timepatterns.length == 0 || event.bookunit==0)
            },
            initAddOrganisation(event)
            {
                this.selectedEvent = event;
                axios.get('/api/organisations')
                    .then(response => {
                        this.organisations = response.data;

                        // now filter out the organisations that are already attached and the owner
                        this.organisations = this.organisations.filter(org=>{
                            if( org.id == event.owner_org_id ) return false;
                            return !event.bookorgs.some(function(test){
                                return (test.id === org.id );
                            });
                        });
                        $("#add_event_org").modal("show");
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },

            addOrgs: function(){
                if (!this.selectedUser)
                    return;

                axios.post('/api/event/' + this.selectedEvent.id + '/organisations', this.selectedOrgs)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.selectedEvent.bookorgs = response.data.orgs;
                        } else {
                            alert(response.data.message);
                        }
                        this.closeDialog();
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },           removeOrganisation: function(event, org){
                axios.delete('/api/event/' + event.id + '/org/' + org.id)
                    .then(response => {
                        if (response.data.result == 'success') {
                            event.bookorgs = event.bookorgs.filter(f => {
                                return f.id != org.id;
                            });
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },

            editEvent(event){
                location.href='/events/' + event.id + '/edit';
            },
            deleteEvent: function (event){
                if(event.bookings.length){
                    var message = 'LET OP: dit spreekuur heeft boekingen; wil je spreekuur en de boekingen verwijderen?'
                } else {
                    var message = 'Dit spreekuur permanent verwijderen?';
                }

                if(confirm(message)) {
                    axios.delete('/api/event/' + event.id)
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.events = this.events.filter(ev => {
                                    return (ev.id != response.data.event.id);
                                });
                            } else {
                                alert(response.data.message);
                            }
                        })
                        .catch(error => {
                            if(error.response.status == 401){
                                location.href='';
                            } else {
                                alert(error);
                            }
                        });
                }
            },
            eventClicked: function (event){
                if(!this.calendarBlocked(event))
                    location.href='/events/calendar/'+event.id;
            },

            closeDialog: function (){
                this.selectedEvent = {};
                this.selectedOrgs = [];
                $("#add_event_org").modal("hide");
            }

        }
    }
</script>
<style>

</style>
