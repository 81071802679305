<template>
  <div class="v-cal">
    <header class="v-cal-header">
      <div class="v-cal-header__actions" style="margin-bottom: 10px">
        <div class="actions-left">
          <button class="v-cal-button">
              <span class="inline-icon cal-icon" :class="{'icon-blocked':patternInfo.length==0 }" @click.stop="showPatternInfo" title="Toon de tijdregels van dit spreekuur"></span>
          </button>
          <button class="v-cal-button" title="Ga naar vandaag" v-if="showTodayButton" @click="goToToday" :class="{ 'v-cal-button--is-active': activeDate && activeDate.isSame( today, 'day' )}">{{ labels.today }}</button>
          <button :disabled="!isPrevAllowed" class="v-cal-button" @click="prev" v-html="labels.back"></button>
          <button :disabled="!isNextAllowed" class="v-cal-button" @click="next" v-html="labels.next"></button>
        </div>
        <div class="actions-right" v-if="true">
          <button class="v-cal-button" v-for="view in availableViews" @click="switchView(view)" :class="{ 'v-cal-button--is-active': activeView === view }" >{{ labels[view] | capitalizeFirstLetter }}</button>
        </div>
      </div>
      <div class="v-cal-header__title-bar">
          <div
              v-if=""
              class="righttop-icon search-icon"
              @click.stop="searchBookings"
              title="Zoek naar een clientnaam in de boekingen voor dit spreekuur">
          </div>
          <h3 class="v-cal-header__title">{{ calendarTitle }}</h3>
      </div>
    </header>
    <component
            :is="activeView"
            :class="'v-cal-content--' + activeView"
            v-bind="activeViewProps"
    ></component>
    <footer class="v-cal-footer"></footer>
      <div v-if="showModal">
          <transition name="modal">
              <div class="modal-mask">
                  <div class="modal-wrapper">
                      <div class="modal-dialog" role="document">
                          <div class="modal-content">
                              <div class="modal-header">
                                  <h5 class="modal-title">Spreekuur tijdregels</h5>
                                  <button type="button" class="close" aria-label="Close">
                                      <span aria-hidden="true" @click="showModal = false">&times;</span>
                                  </button>
                              </div>
                              <div class="modal-body">
                                  <p v-if="patternInfo.length==0">Er zijn nog geen tijdregels voor dit spreekuur.</p>
                                  <ul>
                                      <li v-for="pattern in patternInfo" v-html="pattern"></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </transition>
      </div>
    <div v-if="showSearch">
          <transition name="modal">
              <div class="modal-mask">
                  <div class="modal-wrapper">
                      <div class="modal-dialog" role="document">
                          <div class="modal-content">
                              <div class="modal-header">
                                  <div class="inline-icon search-icon" style="margin-right: 8px"></div>
                                  <h5 class="modal-title">Zoek een cliënt</h5>
                                  <button type="button" class="close" aria-label="Close">
                                      <span aria-hidden="true" @click="showSearch = false">&times;</span>
                                  </button>
                              </div>
                              <div class="modal-body">
                                  <input name="client-search" v-model="searchtext" placeholder="Geef zoeknaam" />
                                  <button @click.stop="startSearch">Zoek</button>
                                  <hr/>
                                  <p v-if="!foundBookings.length && searchDone">Er zijn geen boekingen met deze naam gevonden.</p>
                                  <ul>
                                     <li v-for="booking in foundBookings">
                                         <a href="#" @click.stop.prevent="showBooking(booking)">
                                             {{booking.start|showDateTime}}: {{booking.naam}}
                                        </a>
                                     </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </transition>
      </div>

  </div>
</template>

<script>
    import Event from '../model/Event';
    import config from '../utils/config';
    import { defaultLabels, defaultViews } from '../utils/config';
    import moment from 'moment';
    moment.locale('nl');
    moment.updateLocale('nl', {
        week : {
            dow : 0,
            doy : 4
        }
    });
    import Month from './views/Month';
    import Week from './views/Week';
    import Day from './views/Day';

    export default {
        name: "VueScheduler",
        components: { Month, Week, Day },
        data() {
            return {
                today: moment(),
                activeView: '',
                activeDate: null,
                patternInfo: [],
                showModal: false,
                showSearch: false,
                searchtext: '',
                foundBookings: [],
                searchDone: false
             }
        },
        props: {
            consultation: {
                type: Number,
                default: () => { return 0 }
            },
            events: {
                type: Array,
                default: () => []
            },
            showTodayButton: {
                type: Boolean,
                default: () => config.showTodayButton
            },
            minDate: {
                type: [Date, Object],
                default: () => config.minDate
            },
            maxDate: {
                type: [Date, Object],
                default: () => config.maxDate
            },
            labels: {
                type: Object,
                default: () => config.labels,
                validator(value) {
                    for (const labelKey in defaultLabels ) {
                        if ( !value.hasOwnProperty(labelKey) ) {
                            console.error('Missing prop label: ' + labelKey);
                            return false;
                        }
                    }
                    return true;
                }
            },
            timeRange: {
                type: Array,
                default: () => config.timeRange,
                validator(value) {
                    if ( value.length !== 2 || value[0] > value[1] || value[0] < 0 || value[1] > 23) {
                        console.error('Invalid time range.');
                        return false;
                    }
                    return true;
                }
            },
            availableViews: {
                type: Array,
                default: () => config.availableViews,
                validator (value) {
                    const possible = defaultViews;
                    let error = false;
                    value.forEach(view => {
                        if ( possible.indexOf(view) === -1 ) {
                            console.error('Invalid view: ' + view);
                            error = true;
                        }
                    });
                    return !error;
                }
            },
            initialDate: {
                type: [Date, Object],
                default: () => config.initialDate
            },
            initialView: {
                type: String,
                default: () => config.initialView
            },
            use12: {
                type: Boolean,
                default: () => config.use12
            },
            showTimeMarker: {
                type: Boolean,
                default: () => config.showTimeMarker
            },
            eventDisplay: {
                type: [String, Function],
                default: () => config.eventDisplay
            },
            disableDialog: {
                type: Boolean,
                default: () => config.disableDialog
            },
            eventDialogConfig: {
                type: Object,
                default: () => { return {} }
            }
        },
        mounted() {
            //  Initial setup
            this.activeView = this.initialView;
            this.activeDate = moment(this.initialDate);
            this.getPatternInfo();
        },
        beforeDestroy() {
            EventBus.$off('day-clicked');
            EventBus.$off('time-clicked');
            EventBus.$off('event-clicked');
        },
        methods: {
            searchBookings(){
                this.searchtext = '';
                this.foundBookings = [];
                this.showSearch = true;
            },
            showPatternInfo(){
                this.showModal = true;
            },
            showBooking(booking){
                this.showSearch = false;
                this.activeDate = moment(booking.start);
                EventBus.$emit('show-booking', booking);
            },
            startSearch(){
                if(!this.searchtext){
                    alert('Geef eerst een zoektekst in');
                } else {
                    axios.get('/api/appointments/' + this.consultation + '?search=' + this.searchtext)
                        .then(response => {
                            if(response.data.result=='success') {
                                this.foundBookings = response.data.events;
                                this.searchDone = true;
                            } else {
                                if(response.data.message)
                                    alert(response.data.message);

                                if(response.data.errors)
                                    this.errors = response.data.errors;
                            }
                        })
                        .catch(error => {
                            if(error.response.status == 401){
                                location.href='';
                            } else {
                                alert(error);
                            }
                        });
                }
            },
            getPatternInfo() {
                axios.get('/api/event/' + this.consultation + '/info')
                    .then(response => {
                        if(response.data.result=='success') {
                            this.patternInfo = response.data.patterns;
                        } else {
                            if(response.data.message)
                                alert(response.data.message);

                            if(response.data.errors)
                                this.errors = response.data.errors;
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },
            goToToday() {
                this.activeDate = moment(this.today);
            },
            prev() {
                this.activeDate = moment(this.activeDate.subtract(1, this.activeView + 's'));
            },
            next() {
                this.activeDate = moment(this.activeDate.add(1, this.activeView + 's'));
            },
            setDate(newDate) {
                this.activeDate =  moment(newDate);
            },
            switchView(view) {
                this.activeView = view;
            },
        },
        filters: {
            capitalizeFirstLetter(string) {
                return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
            },
            showDateTime(string) {
                let dateTime = new moment(string);
                return dateTime.format('DD-MM-YYYY HH:mm');
            }
        },
        watch: {
            initialDate() {
                this.activeDate = moment(this.initialDate);
            },
            initialView() {
                this.activeView = this.initialView;
            },
            activeDate() {
                this.$emit(this.activeView + '-changed', this.activeDate.toDate() );
            },
            activeView() {
                this.$emit('view-changed', this.activeView);
            }
        },
        computed: {
            newEvents() {
                return this.events.map(e => {
                    return new Event(e).bindGetter('displayText', this.eventDisplay);
                });
            },
            isPrevAllowed() {
                if ( this.minDate ) {
                    const prevRef = moment(this.activeDate).subtract(1, this.activeView + 's');
                    return this.minDate.isSameOrBefore(prevRef, this.activeView);
                }
                return true
            },
            isNextAllowed() {
                if ( this.maxDate ) {
                    const afterRef = moment(this.activeDate).add(1, this.activeView + 's');
                    return this.maxDate.isSameOrAfter(afterRef, this.activeView);
                }
                return true
            },
            activeViewProps() {
                let props = {
                    activeDate: this.activeDate,
                    minDate: this.minDate,
                    maxDate: this.maxDate,
                    use12: this.use12,
                    events: this.newEvents.filter( event => {
                        return event.date.isSame(this.activeDate, this.activeView);
                    })
                };

                if ( this.activeView === 'week' || this.activeView === 'day') {
                    props.allDayLabel = this.labels.all_day;
                    props.timeRange = this.timeRange;
                    props.showTimeMarker = this.showTimeMarker;
                }
                return props;
            },

            calendarTitle() {
                if ( this.activeDate === null )
                    return '';

                if ( this.activeView === 'month') {
                    return this.activeDate.format('MMMM YYYY');
                }

                if ( this.activeView === 'week' ) {
                    const weekStart = moment(this.activeDate).day(0);
                    const weekEnd = moment(this.activeDate).day(6);
                    return 'Week van ' + weekStart.format('D MMM') + ' - ' + weekEnd.format('D MMM');
                }

                if ( this.activeView === 'day' ) {
                    return this.activeDate.format('dddd MMM D')
                }
            }
        }
    }
</script>

<style>
</style>
