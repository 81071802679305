<template>
    <div id="dayBlocks">
        <ul>
            <li v-if="dayblocks.length == 0">Er zijn nog geen dagblokkeringen voor dit spreekuur.</li>
            <li v-for="block in dayblocks">
                {{block.blockdate|dutchDate}}
                <span class="inline-icon delete-icon" title="Verwijder deze blokkering" @click="deleteBlock(block)"></span>
            </li>
        </ul>
        <div id="datepicker" class="form-group">
            <label>Voeg dagblokkade toe:</label>
            <date-picker v-model="newBlockdate.blockdate" :config="options"></date-picker>
            <button class="btn-primary" @click.stop="addTimeblock">Toevoegen</button>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import datePicker from 'vue-bootstrap-datetimepicker';
    export default {
        name: "DayBlocks",
        props: ['eventid'],
        components: { datePicker },
        data() {
            return {
                dayblocks: [],
                newBlockdate: {blockdate: null},
                options: {
                    format: 'DD-MM-YYYY',
                    useCurrent: false,
                },
            }
        },
        filters: {
            dutchDate: function (val) {
                if (val) {
                    return moment(String(val)).format('DD-MM-Y')
                }
            }
        },
        mounted(){
            axios.get('/api/event/' + this.eventid + '/dayblocks')
                .then(response => {
                    if(response.data.result == 'success') {
                        this.dayblocks = response.data.dayblocks;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        methods: {
            deleteBlock: function (block){
                if(confirm('Deze blokkering vewijderen?')) {
                    axios.delete('/api/dayblock/' + block.id)
                        .then(response => {
                            if (response.data.result == 'success') {
                                this.dayblocks = this.dayblocks.filter(bl => {
                                    return bl.id != response.data.dayblock.id
                                });
                            } else {
                                if (response.data.message)
                                    alert(response.data.message);
                            }
                        })
                        .catch(error => {
                            if (error.response.status == 401) {
                                location.href = '';
                            } else {
                                alert(error);
                            }
                        });
                }
            },
            addTimeblock: function (){
                axios.post('/api/event/' + this.eventid + '/dayblock', this.newBlockdate)
                    .then(response => {
                        if(response.data.result=='success') {
                            this.dayblocks.push(response.data.dayBlock);
                            this.newBlockdate = {blockdate: null};
                        } else {
                            if(response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            }
        }
    }
</script>

<style scoped>
    #dayBlocks{
        padding: 15px;
    }
    ul{
        margin: 0;
        padding-left: 15px;
    }
    #datepicker{
        margin-top: 20px;
    }
</style>
