<template>
    <div class="card-body">
        <table class="table table-bordered table-striped table-responsive" v-if="timepatterns.length > 0">
            <tbody>
            <tr>
                <th>Nr.</th>
                <th>Herhaling</th>
                <th>N</th>
                <th>Weekdag</th>
                <th>Datum</th>
                <th>Van</th>
                <th>Tot</th>
                <th>Eindigt op</th>
                <th></th>
            </tr>
            <tr v-for="(pattern, index) in timepatterns" :key="pattern.id">
                <td>{{ index + 1 }}</td>
                <td>{{ patterns[pattern.pattern] }}</td>
                <td>{{ show_n(pattern) }}</td>
                <td>{{ show_weekday(pattern) }}</td>
                <td>{{ pattern.start_date|dutchDate }}</td>
                <td>{{ pattern.start_time|hoursSeconds }}</td>
                <td>{{ pattern.end_time|hoursSeconds }}</td>
                <td>{{ pattern.end_date|dutchDate }}</td>
                <td>
                    <a @click.prevent.stop="initUpdate(pattern)">
                        <span class="inline-icon edit-icon"></span>
                    </a>
                    &nbsp;
                    <a @click.prevent.stop="deletePattern(pattern)">
                        <span class="inline-icon delete-icon"></span>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>

        <button @click="initAddPattern()" class="btn btn-success " style="padding:5px">
            Tijdregel toevoegen
        </button>

        <div class="modal fade" tabindex="-1" role="dialog" id="add_pattern_model">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title">Tijdregel toevoegen</h4>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="pattern">Herhaling:</label>
                            <select name="pattern"
                                    id="pattern"
                                    class="form-control"
                                    v-model="pattern.pattern">
                                <option value="0">Eénmalig</option>
                                <option value="1">Wekelijks</option>
                                <option value="2">Periodiek</option>
                                <option value="3">Maandelijks</option>
                                <option value="4">Maandelijks, weekdag</option>
                            </select>
                            <p class="error" v-if="errors.pattern">{{errors.pattern[0]}}</p>
                        </div>
                        <div class="form-group" v-if="pattern.pattern==1 || pattern.pattern==4">
                            <label for="weekday">Weekdag:</label>
                            <select name="weekday"
                                    id="weekday"
                                    class="form-control"
                                    v-model="pattern.weekday">
                                 <option v-for="(weekday, key) in weekdays" :value="key">
                                    {{weekday}}
                                </option>
                            </select>
                            <p class="error" v-if="errors.weekday">{{errors.weekday[0]}}</p>
                        </div>

                        <div class="form-group" v-if="pattern.pattern>0">
                            <label for="n">{{ nLabel }}:</label>
                            <input type="text" name="n" id="n2" class="form-control"
                                   v-model="pattern.n">
                            <p class="error" v-if="errors.n">{{errors.n[0]}}</p>
                        </div>
                        <div class="form-group">
                            <label>{{ dateLabel }}:</label>
                            <date-picker v-model="pattern.start_date" :config="options"></date-picker>
                            <p class="error" v-if="errors.start_date">{{errors.start_date[0]}}</p>
                        </div>
                        <div class="form-group">
                            <label for="start-time">Van:</label>
                            <input name="start-time" id="start-time" cols="30" rows="5" class="form-control"
                                   placeholder="hh:mm" v-model="pattern.start_time"></input>
                            <p class="error" v-if="errors.start_time">{{errors.start_time[0]}}</p>
                        </div>
                        <div class="form-group">
                            <label for="end-time">Tot:</label>
                            <input name="end-time" id="end-time" cols="30" rows="5" class="form-control"
                                   placeholder="hh:mm" v-model="pattern.end_time"></input>
                            <p class="error" v-if="errors.end_time">{{errors.end_time[0]}}</p>
                        </div>
                        <div class="form-group" v-if="pattern.pattern!=0">
                            <label>Einddatum:</label>
                            <date-picker v-model="pattern.end_date" :config="options"></date-picker>
                            <p class="error" v-if="errors.end_date">{{errors.end_date[0]}}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Annuleren</button>
                        <button type="button" @click="createPattern()" class="btn btn-primary">Opslaan</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->


        <div class="modal fade" tabindex="-1" role="dialog" id="update_pattern_model">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title">Tijdregel wijzigen</h4>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="pattern">Herhaling:</label>
                            <select name="pattern"
                                    id="pattern2"
                                    class="form-control"
                                    v-model="pattern.pattern">
                                <option value="0">Eénmalig</option>
                                <option value="1">Wekelijks</option>
                                <option value="2">Periodiek</option>
                                <option value="3">Maandelijks</option>
                                <option value="4">Maandelijks, weekdag</option>
                            </select>
                            <p class="error" v-if="errors.pattern">{{errors.pattern[0]}}</p>
                        </div>
                        <div class="form-group" v-if="pattern.pattern==1 || pattern.pattern==4">
                            <label for="weekday">Weekdag:</label>
                            <select name="weekday"
                                    id="weekday2"
                                    class="form-control"
                                    v-model="pattern.weekday">
                                 <option v-for="(weekday, key) in weekdays" :value="key">
                                    {{weekday}}
                                </option>
                            </select>
                            <p class="error" v-if="errors.weekday">{{errors.weekday[0]}}</p>
                        </div>

                        <div class="form-group" v-if="pattern.pattern>0">
                            <label for="n">{{ nLabel }}:</label>
                            <input type="text" name="n" id="n" class="form-control"
                                   v-model="pattern.n">
                            <p class="error" v-if="errors.n">{{errors.n[0]}}</p>
                        </div>
                        <div class="form-group">
                            <label>{{ dateLabel }}:</label>
                            <date-picker v-model="pattern.start_date" :config="options"></date-picker>
                            <p class="error" v-if="errors.start_date">{{errors.start_date[0]}}</p>
                        </div>
                        <div class="form-group">
                            <label for="start-time">Van:</label>
                            <input name="start-time" id="start-time2" cols="30" rows="5" class="form-control"
                                   placeholder="hh:mm"
                                   v-model="pattern.start_time"></input>
                            <p class="error" v-if="errors.start_date">{{errors.start_date[0]}}</p>
                        </div>
                        <div class="form-group">
                            <label for="end-time">Tot:</label>
                            <input name="end-time" id="end-time2" cols="30" rows="5" class="form-control"
                                   placeholder="hh:mm"
                                   v-model="pattern.end_time"></input>
                            <p class="error" v-if="errors.end_time">{{errors.end_time[0]}}</p>
                        </div>
                        <div class="form-group">
                            <label>Einddatum:</label>
                            <date-picker v-model="pattern.end_date" :config="options"></date-picker>
                            <p class="error" v-if="errors.end_date">{{errors.end_date[0]}}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Annuleren</button>
                        <button type="button" @click="updatePattern()" class="btn btn-primary">Wijzigingen opslaan</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
    import datePicker from 'vue-bootstrap-datetimepicker';
    import moment from 'moment';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    export default {
        data(){
            return {
                errors: [],
                timepatterns: [],
                update_pattern: {},
                pattern: {
                    start_date: new Date(),
                    start_time: '',
                    end_time: '',
                    pattern: 0,
                    weekday: 0,
                    n: 2
                },
                weekdays: [
                    '',
                    'Zondag',
                    'Maandag',
                    'Dinsdag',
                    'Woensdag',
                    'Donderdag',
                    'Vrijdag',
                    'Zaterdag'
                ],
                patterns: [
                    'Eénmalig',
                    'Wekelijks',
                    'Periodiek',
                    'Maandelijks, vaste dag',
                    'Maandelijks, weekdag'
                ],
                options: {
                    format: 'DD-MM-Y',
                    useCurrent: false,
                }
            }
        },
        filters: {
            dutchDate: function(val) {
                if (val) {
                    return moment(String(val)).format('DD-MM-Y')
                }
            },
            shortDate: function(val) {
                if (val) {
                    return moment(String(val)).format('D-M-Y')
                }
            },
            hoursSeconds: function(val) {
                if (val) {
                    return val.substring(0,5);
                }
            }
        },
        components: {
            datePicker
        },
        props: {
            eventid: Number
        },
        mounted() {
            this.readTimepatterns(this.eventid);
        },
        computed: {
            dateLabel: function () {
                if (this.pattern.pattern==0) return 'Datum';
                if (this.pattern.pattern>0) return 'Startdatum';
            },

            nLabel: function () {
                if (this.pattern.pattern==0) return '';
                if (this.pattern.pattern==1) return 'Om de hoeveel weken';
                if (this.pattern.pattern==2) return 'Om de hoeveel dagen';
                if (this.pattern.pattern==3) return 'Dag van de maand';
                if (this.pattern.pattern==4) return 'N-de weekdag';
            }
        },
        methods: {
            show_n(pattern){
                if (pattern.pattern > 2) return pattern.n + 'e';
                if (pattern.pattern > 0) return pattern.n;
                return '-';
            },

            show_weekday(pattern){
                if (pattern.pattern == 1 || pattern.pattern == 4) return this.weekdays[pattern.weekday];
                return '-';
            },

            readTimepatterns(eventId)
            {
                axios.get('/api/timepatterns/' + eventId)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.timepatterns = response.data.timepatterns;
                    } else {
                        this.errors.push(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            },

            updatePattern()
            {
                axios.patch('/api/timepatterns/' + this.pattern.id, this.pattern)
                .then(response => {
                    if (response.data.result == 'success') {
                        $("#update_pattern_model").modal("hide");
                        // get the index of the updated tp
                        let changedPatternIndex = this.timepatterns.findIndex(function(tp){return(tp.id == response.data.pattern.id)})
                        // replace the org using splice to force Vue to update
                        this.timepatterns.splice(changedPatternIndex, 1, response.data.pattern);
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                        if(response.data.errors)
                            this.errors = response.data.errors;
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            },

            deletePattern(pattern)
            {
                let conf = confirm("Deze regel verwijderen?");
                if (conf === true) {
                    axios.delete('/api/timepatterns/' + pattern.id)
                    .then(response => {
                        if (response.data.result=='success') {
                            this.timepatterns = this.timepatterns.filter(f => {
                                return f.id != pattern.id;
                            });
                        } else if (response.data.result=='confirm') {
                            if (confirm(response.data.message)) {
                                this.removePattern(pattern);
                            }
                        } else {
                            if(response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
                }
            },

            removePattern(pattern) // the removal of the pattern with bookings was confirmed by the user
            {
                axios.delete('/api/timepatterns/' + pattern.id + '?force=true')
                    .then(response => {
                        if (response.data.result=='success') {
                            this.timepatterns = this.timepatterns.filter(f => {
                                return f.id != pattern.id;
                            });
                        } else {
                            if (response.data.message)
                                alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },

            initAddPattern()
            {
                this.errors = [];
                this.reset();
                $("#add_pattern_model").modal("show");
            },

            initUpdate(pattern)
            {
                this.errors = [];
                // clone the pattern
                this.pattern = { ...pattern };
                this.pattern.start_date = moment(this.pattern.start_date).format('DD-MM-Y');
                if (this.pattern.end_date)
                    this.pattern.end_date = moment(this.pattern.end_date).format('DD-MM-Y');
                this.pattern.start_time = this.pattern.start_time.substring(0,5);
                this.pattern.end_time = this.pattern.end_time.substring(0,5);
                $("#update_pattern_model").modal("show");
            },

            createPattern()
            {
                axios.post('/api/timepatterns', this.pattern)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.reset();
                        this.timepatterns.push(response.data.timepattern);
                        $("#add_pattern_model").modal("hide");
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                        if(response.data.errors)
                            this.errors = response.data.errors;
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            },

            reset()
            {
                this.pattern.start_date = new Date();
                this.pattern.end_date = null;
                this.pattern.start_time = '09:00';
                this.pattern.end_time = '17:00';
                this.pattern.n = 1;
                this.pattern.weekday = 0;
                this.pattern.pattern = 1;
                this.pattern.event_id = this.eventid;
            }
        }
    }
</script>
