// src/components/Calendar.vue

<template>
    <v-scheduler
        :events="eventblocks"
        :time-range="[8,20]"
        :available-views="['week','month']"
        @week-changed="weekChanged"
        @month-changed="monthChanged"
        @view-changed="viewChanged"
        :initial-view="'week'"
        :show-today-button="true"
        :event-display="'name'"
        :event-dialog-config="dialogConfig"
        :disable-dialog="true"
        :consultation="consultation"
    >
    </v-scheduler>
</template>

<script>

import VueScheduler  from './v_calendar_scheduler/components/VueScheduler';
import moment from 'moment';

export default {
    components: {
        'v-scheduler': VueScheduler
    },
    data() {
        return {
            eventblocks: [],
            curDate: null,
            dialogConfig: {
                fields: []
            }
        }
    },
    props: ['consultation'],
    mounted() {
    },
    methods: {
        monthChanged(newDate) {
            // newDate is the day in the requested month
            // get first day of the month
            this.curDate = moment(newDate).startOf('month');
            this.readEventblocks(31);
        },
        weekChanged(newDate) {
            // newDate is the current weekday in the requested week (so not neccessarily monday).
            // get monday
            this.curDate = moment(newDate).weekday(0);
            this.readEventblocks(7);
        },
        viewChanged(newView){
            switch(newView){
                case 'week':
                    break;
                case 'month':
                    this.monthChanged(this.curDate.startOf('month'));
                    break;
            }
        },
        readEventblocks(days) {
            axios.get('/api/eventblocks/' + this.consultation + '?from=' + this.curDate.format('Y-MM-DD') + '&n=' + days, this.pattern)
            .then(response => {
                this.eventblocks = response.data.eventblocks;
                EventBus.$emit('events-read', '');
            })
            .catch(error => {
                if(error.response.status == 401){
                    location.href='';
                } else {
                    alert(error);
                }
            });
        }
    }
}
</script>

<style>
</style>
