<template>
    <div class="card">
        <div class="card-header">Organisatiebeheer - locaties van  {{org.name}}</div>

        <div class="card-body">
            <table class="table">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>Adres</th>
                    <th>Plaats</th>
                    <th>E-mail</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="loc in locations" :key="loc.id">
                    <td>{{loc.name}}</td>
                    <td>{{loc.address}}</td>
                    <td>{{loc.city}}</td>
                    <td>{{loc.email}}</td>
                    <td>
                        <a @click.prevent.stop="editLocation(loc)" title="Wijzig deze locatie">
                            <span class="inline-icon edit-icon"></span>
                        </a>
                        <a @click.prevent.stop="deleteLocation(loc)" title="Verwijder deze locatie">
                            <span class="inline-icon delete-icon"></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer">
            <button @click.stop.prevent="newLocation">Nieuwe locatie aanmaken voor {{org.name}}</button>
        </div>

        <div class="modal fade" tabindex="-1" role="dialog" id="edit_loc_model">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title">{{dialogTitle}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" v-if="errors.length > 0">
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </div>

                        <div class="form-group">
                            <label for="loc_name">Naam:</label>
                            <input type="text" name="loc_name" id="loc_name" class="form-control"  v-model="selectedLoc.name">
                            <p class="error" v-if="errors.name">{{errors.name[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="loc_address">Adres:</label>
                            <input type="text" name="loc_address" id="loc_address" class="form-control"  v-model="selectedLoc.address">
                            <p class="error" v-if="errors.address">{{errors.address[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="loc_city">Plaats:</label>
                            <input type="text" name="loc_city" id="loc_city" class="form-control"  v-model="selectedLoc.city">
                            <p class="error" v-if="errors.city">{{errors.city[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="loc_zipcode">Postcode:</label>
                            <input type="text" name="loc_zipcode" id="loc_zipcode" class="form-control"  v-model="selectedLoc.zipcode">
                            <p class="error" v-if="errors.zipcode">{{errors.zipcode[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="loc_email">E-mail:</label>
                            <input type="text" name="loc_email" id="loc_email" class="form-control"  v-model="selectedLoc.email">
                            <p class="error" v-if="errors.email">{{errors.email[0]}}</p>
                        </div>

                        <div class="form-group">
                            <label for="loc_website">Website:</label>
                            <input type="text" name="loc_website" id="loc_website" class="form-control"  v-model="selectedLoc.website">
                            <p class="error" v-if="errors.website">{{errors.website[0]}}</p>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Annuleren</button>
                        <button type="button" @click="saveLocation()" class="btn btn-primary">Opslaan</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
    export default {
        data(){
            return {
                locations: [],
                org: {},
                errors: [],
                selectedLoc: {},
                dialogTitle: ''
            }
        },
        props: ['organisation'],
        mounted() {
            axios.get('/api/organisation/' + this.organisation + '/locations')
                .then(response => {
                    if(response.data.result == 'success') {
                        this.locations = response.data.locations;
                        this.org = response.data.org;
                    } else {
                        if(response.data.message)
                            alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        methods: {
            newLocation: function() {
                this.dialogTitle = 'Nieuwe locatie';
                this.selectedLoc = {};
                this.errors = [];
                $("#edit_loc_model").modal("show");
            },

            saveLocation: function (){
                if(this.selectedLoc.id){
                    this.updateLocation();
                } else {
                    this.saveNewLocation();
                }
            },
            saveNewLocation: function(){
                this.errors = [];
                axios.post('/api/organisation/' + this.org.id + '/location', this.selectedLoc)
                .then(response => {
                    if(response.data.result == 'success'){
                        this.locations.push(response.data.location);
                        this.selectedLoc = {};
                        $("#edit_loc_model").modal("hide");
                    } else {
                        if(response.data.message)
                            alert(response.data.message);

                        if(response.data.errors)
                            this.errors = response.data.errors;
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            },

            deleteLocation: function (loc){
                if(confirm('Deze locatie permanent verwijderen?')) {
                    axios.delete('/api/locations/' + loc.id)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.locations = this.locations.filter(f => {
                                return f.id != loc.id;
                            });
                            this.selectedLoc = {};
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
                }
            },

            updateLocation: function (){
                axios.patch('/api/locations/' + this.selectedLoc.id, this.selectedLoc)
                    .then(response => {
                        if(response.data.result == 'success') {
                            // get the index of the updated loc
                            let changedLocIndex = this.locations.findIndex(function(loc){return(loc.id == response.data.location.id)})
                            // replace the org using splice to force Vue to update
                            this.locations.splice(changedLocIndex, 1, response.data.location);
                            this.selectedLoc = {};
                            $("#edit_loc_model").modal("hide");
                        } else {
                           this.errors = response.data.errors;
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },

            editLocation: function (loc){
                this.selectedLoc = { ...loc };
                this.errors = [];
                $("#edit_loc_model").modal("show");
            },
        }
    }
</script>
<style>

</style>
