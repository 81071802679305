<template>
    <div class="card">
        <div class="card-header">Gebruikersbeheer</div>

        <div class="card-body">
            <table class="table">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>E-mail</th>
                    <th>Rol</th>
                    <th>Organisaties</th>
                    <th></th>
                </tr>
                </thead>

                <tbody @click.stop="usersClick">
                <tr v-for="user in users" :key="user.id" @click.stop="">
                    <td>{{user.name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.role}}</td>
                    <td class="nowrap">
                        <ul class="no-bullets" v-if="user.role != 'superadmin'">
                            <li v-for="org in user.organisations">
                                {{org.name}}
                                <a @click.prevent.stop="removeOrganisation(user, org)">
                                    <span class="inline-icon delete-icon"></span>
                                </a>
                            </li>
                            <li>
                                <a @click.prevent.stop="initAddOrganisation(user)" style="color: #EE7A3A">
                                    Organisatie koppelen&nbsp;<span class="inline-icon add-user-org add-icon"></span>
                                </a>
                            </li>
                        </ul>
                    </td>
                    <td>
                        <button
                            type="button"
                            class="user-delete-button btn btn-danger"
                            @click.stop="deleteUser(user)"
                            v-if="user.role != 'superadmin'"
                        >Gebruiker Verwijderen</button>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <input id="new_user_name" type="text" name="new_user_name" v-model="newUser.name" placeholder="Naam" />
                            <div class="alert alert-danger" v-if="errors.name">{{errors.name[0]}}</div>
                        </td>
                        <td>
                            <input id="new_user_email" type="text" name="new_user_email" v-model="newUser.email" placeholder="E-mail adres" />
                            <div class="alert alert-danger" v-if="errors.email">{{errors.email[0]}}</div>
                        </td>
                        <td>
                            <select id="new_user_role" name="new_user_role" v-model="newUser.role">
                                <option value="user">user</option>
                                <option value="admin">admin</option>
                            </select>
                        </td>
                        <td></td>
                        <td>
                            <button type="submit" class="btn btn-primary" id="add-user-btn" @click.stop="addUser">Gebruiker toevoegen</button>
                        </td>
                    </tr>

                </tfoot>
            </table>

            <div class="modal fade" tabindex="-1" role="dialog" id="add_user_org">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <h4 class="modal-title">Organisatie toevoegen voor {{selectedUser.name}}</h4>

                            <div class="alert alert-danger" v-if="errors.length > 0">
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                            <ul class="no-bullets">
                                <li v-for="org in organisations" :key="org.id">
                                    <input type="checkbox" name="add_org" :value="org.id" class="add_org_checkbox" v-model="selectedOrgs" />
                                    &nbsp;{{org.name}}
                                </li>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" value="" @click="closeDialog">Annuleren</button>
                            <button type="button" @click="addOrgs" class="btn btn-primary">Opslaan</button>
                        </div>
                    </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
            </div><!-- /.modal -->
        </div>
        <div class="card-footer">
        </div>
    </div>

</template>

<script>
    export default {
        data(){
            return {
                users: [],
                newUser: {name: ''},
                errors: {},
                organisations: [],
                selectedUser: {"name": ""},
                selectedOrgs: []
            }
        },
        props: {
        },
        mounted() {
            axios.get('/api/users')
                .then(response => {
                    this.users = response.data;
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },
        computed: {
        },
        methods: {
            usersClick: function(e) {
                if(true) {
                    alert()
                    let user = this.users[e.target.closest('tr').rowIndex-1];
                    this.initAddOrganisation(user);
                }
            },

            addUser: function(){
                this.errors = {};
                if (!this.newUser.name){
                    alert('Vul eerst de naam in van de nieuwe gebruiker');
                    return;
                }
                if (!this.newUser.email){
                    alert('Vul een e-mail adres in van de nieuwe gebruiker');
                    return;
                }
                axios.post('/api/users', this.newUser)
                .then(response => {
                    if(response.data.result == 'success'){
                        this.users.push(response.data.user);
                        this.newUser = {};
                    } else {
                        if(response.data.message)
                            alert(response.data.message);

                        if(response.data.errors)
                            this.errors = response.data.errors;
                    }
                })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },

            removeOrganisation: function(user, org){
                axios.delete('/api/user/' + user.id + '/org/' + org.id)
                .then(response => {
                    if (response.data.result == 'success') {
                         user.organisations = user.organisations.filter(f => {
                            return f.id != org.id;
                        });

                        this.newUser = {};
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            },

            initAddOrganisation(user)
            {
                this.selectedUser = user;
                axios.get('/api/organisations')
                .then(response => {
                    this.organisations = response.data;

                    // now filter out the organisatons that are already attached
                    this.organisations = this.organisations.filter(function(org) {
                        return !user.organisations.some(function(test){
                            return test.id === org.id;
                        });
                    });
                    $("#add_user_org").modal("show");
                })
                .catch(error => {
                    if(error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
            },

            addOrgs: function(){
                if (!this.selectedUser)
                    return;

                axios.post('/api/user/' + this.selectedUser.id + '/organisations', this.selectedOrgs)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.selectedUser.organisations = response.data.orgs;
                        } else {
                            alert(response.data.message);
                        }
                        this.closeDialog();
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },

            deleteUser: function (user){
                if(confirm('Deze gebruiker permanent verwijderen?')) {
                    axios.delete('/api/users/' + user.id)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.users = this.users.filter(f => {
                                return f.id != user.id;
                            });

                            this.newUser = {};
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
                }
            },

            closeDialog: function (){
                this.selectedUser = {};
                this.selectedOrgs = [];
                $("#add_user_org").modal("hide");
            }
        }
    }
</script>
